
	import { Component, Vue, Prop } from 'vue-property-decorator';
	/// icons list
	import IconClose from '@pixcap/ui-library/components/Icons/IconClose.vue';

	@Component({
		name: 'Modal',
		components: {
			IconClose,
		},
	})
	export default class Modal extends Vue {
		@Prop() readonly width: number;
		@Prop() readonly height: number | string;
		@Prop() readonly padding: number;
		@Prop() readonly withoutCloseButton: boolean;
		@Prop() readonly enableShortcuts: boolean;
		@Prop() readonly closeButtonOutSide: boolean;
		@Prop({ default: '' }) readonly className: string;
		@Prop() readonly styleModal: object;
		@Prop() readonly backdropStyle: any;
		@Prop({ default: false }) readonly transition: boolean;
		@Prop({ default: false }) readonly isMobileScreen: boolean;

		_handleKeyDown;

		$refs: { refModalContent: HTMLElement };

		get modalStyle() {
			return {
				'max-width': `${this.width}px`,
				'max-height': typeof this.height === 'string' ? this.height : `${this.height}px`,
				padding: `${this.padding}px`,
				...this.styleModal,
			};
		}

		get closeButtonClassName() {
			let className = `modal__close `;
			if (this.closeButtonOutSide) {
				className += `modal__close--outside`;
			}
			return className;
		}

		handleCloseModal() {
			this.$nextTick(() => {
				if (this.transition) {
					this.$refs.refModalContent.style.transform = this.isMobileScreen ? `translateY(100%)` : `translateX(100%)`;
					setTimeout(() => {
						this.$emit('closeModal');
					}, 300);
				} else {
					this.$emit('closeModal');
				}
			});
		}

		handleKeyDown(e: KeyboardEvent) {
			if (e.key === 'Escape') {
				e.preventDefault();
				e.stopPropagation();
				this.handleCloseModal();
			}
		}

		mounted() {
			this.$nextTick(() => {
				if (this.transition) {
					setTimeout(() => {
						this.$refs.refModalContent.style.transform = this.isMobileScreen ? `translateY(0px)` : `translateX(0px)`;
					}, 100);
				}
			});
			if (this.enableShortcuts) {
				this._handleKeyDown = this.handleKeyDown.bind(this);
				document.addEventListener('keydown', this._handleKeyDown);
			}
		}

		beforeDestroy() {
			if (this._handleKeyDown) document.removeEventListener('keydown', this._handleKeyDown);
		}
	}
