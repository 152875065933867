
	import { Component, Prop, Mixins } from 'vue-property-decorator';

	import { UnreachableError } from '@pixcap/ui-library/utils/errors';
	import TypographyText from '@pixcap/ui-library/common/typography/TypographyText.vue';
	import TextMixin from '@pixcap/ui-library/common/typography/TextMixin';
	import { TextSize } from '@pixcap/ui-library/common/typography/typography';

	@Component({
		name: 'HeadingText',
		components: {
			TypographyText,
		},
	})
	export default class HeadingText extends Mixins(TextMixin) {
		@Prop({ default: 'medium' }) readonly size: TextSize;
		get componentTagName() {
			if (this.tagName) {
				return this.tagName;
			}
			switch (this.size) {
				case 'large':
					return 'h1';
				case 'medium':
					return 'h2';
				case 'small':
					return 'h3';
				default:
					throw new UnreachableError(this.size);
			}
		}
	}
