
	import { Component, Mixins, Prop } from 'vue-property-decorator';
	import { TextSize } from '@pixcap/ui-library/common/typography/typography';
	import TypographyText from '@pixcap/ui-library/common/typography/TypographyText.vue';
	import TextMixin from '@pixcap/ui-library/common/typography/TextMixin';

	@Component({
		name: 'CaptionText',
		components: {
			TypographyText,
		},
	})
	export default class CaptionText extends Mixins(TextMixin) {
		@Prop({ default: 'medium' }) readonly size: TextSize;
		get componentTagName() {
			return this.tagName || 'span';
		}
	}
