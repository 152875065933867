export enum LIBRARY_ITEM_TYPE {
  STATIC_MOCKUP_TEMPLATES = "STATIC_MOCKUP_TEMPLATES",
  ANIMATED_MOCKUP_TEMPLATES = "ANIMATED_MOCKUP_TEMPLATES",
  DESIGN_TEMPLATES = "DESIGN_TEMPLATES",
  ANIMATED_DESIGN_TEMPLATES = "ANIMATED_DESIGN_TEMPLATES",
  ANIMATED_MODELS = "ANIMATED_MODELS",
  STATIC_MODELS = "STATIC_MODELS",
  ANIMATIONS = "ANIMATIONS",
  POSES = "POSES",
  CHARACTERS = "CHARACTERS",
  FRAMES = "FRAMES",
  TEXT = "TEXT",
  TEXT_SHAPES = "TEXT_SHAPES",
  SHAPES = "SHAPES",
}

export enum LIBRARY_RESOURCE_TYPE {
  ELEMENTS = "elements",
  MODELS = "models",
  CHARACTERS = "characters",
  FRAMES = "frames",
  TEXT = "text",
  TEXT_SHAPES = "text_shapes",
  SHAPES = "shapes",
  ANIMATIONS = "animations",
  POSES = "poses",
  DESIGN_TEMPLATES = "design_templates",
}
