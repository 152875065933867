
	import { Component, Vue, Prop } from 'vue-property-decorator';
	@Component({
		name: 'Popup',
	})
	export default class Popup extends Vue {
		@Prop() isCustomToggle: boolean;
		@Prop() openOnHover: boolean;
		@Prop() closeOnContentClick: boolean;
		@Prop({ default: true }) autoClose: boolean;
		@Prop({ default: false }) defaultShow: boolean;
		@Prop({ default: 'bottom' }) placementY: 'top' | 'bottom';
		@Prop({ default: 'right' }) placementX: 'left' | 'right';
		showPopup = false;

		get className() {
			return [this.showPopup ? 'popup-open' : '', `popup-content--${this.placementY}`, `popup-content--${this.placementX}`];
		}

		handleHoverPopup() {
			if (!this.openOnHover) return;
			this.open();
		}
		handleMouseLeavePopup() {
			if (!this.openOnHover) return;
			this.close();
		}
		handleClickPopup() {
			if (this.closeOnContentClick) this.close();
		}
		togglePopup() {
			if (this.isCustomToggle) return;
			this.toggle();
		}
		onClickOutside() {
			if (this.autoClose) this.close();
		}
		close() {
			this.showPopup = false;
			this.$emit('closed');
		}
		open() {
			this.showPopup = true;
			this.$emit('onOpen');
		}
		toggle() {
			if (this.showPopup) {
				this.close();
			} else {
				this.open();
			}
		}

		created() {
			this.showPopup = this.defaultShow;
		}
	}
