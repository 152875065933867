
	import { Component, Vue, Prop } from 'vue-property-decorator';
	@Component({
		name: 'PopupMenu',
	})
	export default class PopupMenu extends Vue {
		@Prop({ default: 'md' }) readonly size: 'sm' | 'md' | 'lg';
		@Prop({ default: 'right' }) readonly pos: 'right' | 'left' | 'center' | 'topRight' | 'topLeft' | 'topCenter';
		@Prop() readonly width: string;
		get popupClassName() {
			let className = 'popup';
			className += ` popup--${this.pos} popup--${this.size}`;
			return className;
		}
	}
