
	import { Component, Prop, Mixins } from 'vue-property-decorator';

	import TypographyText from '@pixcap/ui-library/common/typography/TypographyText.vue';
	import TextMixin from '@pixcap/ui-library/common/typography/TextMixin';
	import { TextSize } from '@pixcap/ui-library/common/typography/typography';

	@Component({
		name: 'BodyText',
		components: {
			TypographyText,
		},
	})
	export default class BodyText extends Mixins(TextMixin) {
		@Prop({ default: 'medium' }) readonly size: TextSize;

		get componentTagName() {
			return this.tagName || 'span';
		}
	}
